import { useNavigation } from 'features/navigation/queries';
import { motion } from 'framer-motion';
import { memo, useCallback, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { Category } from 'types/Category';
import { Navigation } from 'types/Navigation';
import { DEFAULT_TRANSITION_TIMING, DROPDOWN_ANIMATION } from '../../../../../constants/animations';
import CategoryDropDownMiddle from '../CategoryDropDownMiddle/CategoryDropDownMiddle';
import {
  StyledCatDropDownClickDetector,
  StyledCategoryDropDownLeft,
  StyledCategoryDropDownRight,
  StyledDropDownWrapper,
} from './CategoryDropDown.styled';

interface CategoryDropDownProps {
  navigation?: Navigation;
  showCategoryDropDown?: boolean;
  toggleCategoryDropDown: () => void;
}

const CategoryDropDown = ({ showCategoryDropDown, toggleCategoryDropDown }: CategoryDropDownProps) => {
  const [activeL1Category, setActiveL1Category] = useState<Category>();

  const { data: navigation } = useNavigation();

  const setCurrentActiveL1Category = useCallback(
    (activeCategory: Category) => {
      if (!activeL1Category || activeL1Category.code !== activeCategory.code) {
        setActiveL1Category(activeCategory);
      }
    },
    [activeL1Category],
  );

  useEffect(() => {
    if (!activeL1Category) {
      setActiveL1Category(navigation?.categories?.[0]);
    }
  }, [navigation]);

  if (!navigation) return null;

  return (
    <motion.div
      animate={showCategoryDropDown ? 'animate' : 'exit'}
      initial="exit"
      transition={DEFAULT_TRANSITION_TIMING}
      variants={DROPDOWN_ANIMATION}
    >
      <StyledCatDropDownClickDetector>
        <StyledDropDownWrapper show={!!showCategoryDropDown}>
          <StyledCategoryDropDownLeft
            activeL1Category={activeL1Category}
            navigation={navigation}
            setCurrentL1Category={setCurrentActiveL1Category}
            toggleCategoryDropDown={toggleCategoryDropDown}
          />

          <StyledCategoryDropDownRight>
            {navigation?.categories?.map((category) => (
              <CategoryDropDownMiddle
                key={category?.code}
                category={category}
                show={activeL1Category?.code === category?.code}
                toggleCategoryDropDown={toggleCategoryDropDown}
              />
            ))}
          </StyledCategoryDropDownRight>
        </StyledDropDownWrapper>
      </StyledCatDropDownClickDetector>
    </motion.div>
  );
};

export default memo(CategoryDropDown, isEqual);
